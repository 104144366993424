<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"/>

    <!-- filter -->
    <ARow :gutter="[8, 8]">
      <ACol :xl="6" :md="8" :sm="12">
        <a-month-picker
          style="width:100%;"
          show
          v-model:value="state.params.start_date"
          placeholder="Dari Bulan"
          :disabled-date="disabledStartDate"
          format="MMMM YYYY"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12">
        <a-month-picker
          style="width:100%;"
          v-model:value="state.params.end_date"
          placeholder="Sampai Bulan"
          :disabled-date="disabledEndDate"
          format="MMMM YYYY"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" v-can:hide.distributor>
        <filter-regional
          style="width:100%;"
          v-model:value="state.params.region"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" v-can:hide.distributor>
        <filter-province
          style="width:100%;"
          v-model:value="state.params.provinsi"
          v-model:region="state.params.region"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" v-can:hide.distributor>
        <filter-area
          style="width:100%;"
          v-model:value="state.params.area"
          v-model:provinsi="state.params.provinsi"
          v-model:region="state.params.region"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" v-can:hide.distributor>
        <filter-kabupaten-kota
          style="width:100%;"
          v-model:value="state.params.kabupaten"
          v-model:kabupaten="state.params.kabupaten"
          v-model:area="state.params.area"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" v-can:hide.distributor>
        <filter-distributor
          style="width:100%;"
          v-model:value="state.params.distributor"
            v-model:region="state.params.region"
            v-model:area="state.params.area"
            v-model:provinsi="state.params.provinsi"
            v-model:kabupaten="state.params.kabupaten"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12">
        <filter-brands
          style="width:100%;"
          v-model:value="state.params.brand"
          v-model:brand="state.params.brand"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12">
        <filter-products
          style="width:100%;"
          v-model:value="state.params.product"
          v-model:brand="state.params.brand"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12">
        <a-select
          style="width:100%;"
          :options="[
            { value: null, label: 'Pilih UOM' },
            { value: 1, label: 'TON' },
            { value: 2, label: 'ZAK' },
          ]"
          placeholder="Semua UOM"
          v-model:value="state.params.uom"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12">
        <filter-segment
          style="width:100%;"
          :mode="null"
          placeholder="Pilih Tipe Customer"
          v-model:value="state.params.segment"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12">
        <a-input-search
          style="width:100%;"
          v-model:value="state.params.q"
          placeholder="Cari ..."
          @search="fetchData"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12">
        <a-button
          type="primary"
          title="cari"
          @click="fetchData"
          :loading="state.isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!state.isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </ACol>
    </ARow>

     <div class="row justify-content-end">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <DownloadExcel
          :url="state.endpoint"
          :params="queryParams()"
          namefile="transaksi-toko"
          @errors="errorMessage"/>
      </div>
    </div>

    <div class="table-responsive mt-4">
      <md-table
        :columns="state.columns"
        :data-source="state.data"
        :row-selection="rowSelection"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: state.meta.total,
          pageSize: state.meta.per_page,
          current: state.meta.page,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        @change="handleTableChange"
        :loading="state.isFetching">
        <template #no="{ index }">
          <span>
            {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
          </span>
        </template>
        <template #nilai="{ text }">
          <span>{{ text ? new Intl.NumberFormat("id-ID").format(text) : 0 }}</span>
        </template>
      </md-table>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, onMounted, ref } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterBrands from '@/components/filter/FilterBrand'
import FilterProducts from '@/components/filter/FilterProduct'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterSegment from '@/components/filter/FilterSegment'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import moment from 'moment'
import useUserInfo from '@/composables/useUserInfo'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
  components: {
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterDistributor,
    FilterBrands,
    FilterProducts,
    FilterKabupatenKota,
    FilterSegment,
    DownloadExcel,
  },
  setup() {
    const errorMessage = ref()
    const { isDistributor, vendor_id } = useUserInfo()
    const state = reactive({
      columns: [
        {
          title: 'No',
          slots: { customRender: 'no' },
        },
        {
          title: 'Bulan',
          dataIndex: 'bulan',
        },
        {
          title: 'Tahun',
          dataIndex: 'tahun',
        },
        {
          title: 'ID Toko',
          dataIndex: 'id_bk',
        },
        {
          title: 'Nama Toko',
          dataIndex: 'toko_name',
        },
        {
          title: 'Provinsi Gudang',
          dataIndex: 'wr_prov',
        },
        {
          title: 'Kabupaten / Kota Gudang',
          dataIndex: 'wr_kab',
        },
        {
          title: 'Region Gudang',
          dataIndex: 'wr_reg',
        },
        {
          title: 'Area Gudang',
          dataIndex: 'wr_area',
        },
        {
          title: 'Kode Distributor',
          dataIndex: 'code_distri',
        },
        {
          title: 'Nama Distributor',
          dataIndex: 'distri_name',
        },
        {
          title: 'Tipe Customer',
          dataIndex: 'type_name',
        },
        {
          title: 'Produk',
          dataIndex: 'product_name',
        },
        {
          title: 'Kode MD Royalty',
          dataIndex: 'kode_md_royalty',
        },
        {
          title: 'Nama MD Royalty',
          dataIndex: 'nama_md_royalty',
        },
        {
          title: 'UoM Produk',
          dataIndex: 'uom',
        },
        {
          title: 'Total Volume',
          dataIndex: 'total_volume',
          slots: { customRender: 'nilai' },
        },
        {
          title: 'Total Transaksi',
          dataIndex: 'total_trx',
        },
        {
          title: 'Cluster',
          dataIndex: 'cluster_name',
        },
        {
          title: 'SSM',
          dataIndex: 'user_ssm',
        },
        {
          title: 'ASM',
          dataIndex: 'user_sm',
        },
        {
          title: 'TSO',
          dataIndex: 'user_am',
        },
      ],
      endpoint: '/api/report/transaksi-toko',
      data: [],
      isFetching: false,
      params: extractQueryParams({
        q: '',
        region: [],
        area: [],
        provinsi: [],
        kabupaten: [],
        product: [],
        brand: [],
        distributor: [],
        type: [],
        uom: [],
        start_date: moment(new Date()),
        end_date: moment(new Date()),
        page: 1,
        'per-page': 10,
      }),
      meta: {
        per_page: 10,
        page: 1,
        total: 0,
      },
    })

    const disabledStartDate = (current) => {
      return current && current > moment(state.params.end_date)
    }

    const disabledEndDate = (current) => {
      return current && moment(state.params.start_date) >= current
    }

    const handleTableChange = (pag, filters, sorter) => {
      state.params.page = pag.current
      state.params['per-page'] = pag.pageSize
      
      fetchData()
    }

    const queryParams = () => {
      let params = state.params

      if (state.params.start_date) {
        state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
      }
      if (state.params.end_date) {
        state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
      }

      return updateUrlWithQuery(params)
    }

    const fetchData = () => {
      state.isFetching = true
      apiClient
        .get(state.endpoint, {
          params: queryParams(),
        })
        .then(({ data }) => {
          const { items, _meta } = data

          state.data = items
          state.meta.page = _meta.currentPage
          state.meta.per_page = _meta.perPage
          state.meta.total = _meta.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    onMounted(() => {
      // validation roles default params
      if (isDistributor.value) {
        state.params.distributor.push(vendor_id.value)
      }
      
      fetchData()
    })

    return{
      errorMessage,
      state,
      queryParams,
      fetchData,
      handleTableChange,
      disabledStartDate,
      disabledEndDate,
    };
  },
});
</script>
